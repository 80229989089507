<!-- 客户管理 -- 往届生续费 -- 列表 -->
<template>
	<div>
		<list-template
				:current-page="page"
				:is-check-input-box="false"
				:isDownload="true"
				:loading="loading"
				:search-config="searchConfig"
				:table-config="tableConfig"
				:table-data="tableData"
				:total="total"
				@onChangePage="handleCurrentChange"
				@onExportData="onDownload"
				@onHandle="handleEdit"
				@onSearch="onSearch"
		></list-template>
	</div>
</template>

<script>
import {mapState} from "vuex";
import tools from "@/utils/tools";
import ListTemplate from "@/components/pages/List.vue"

export default {
	_config: {"route": {"path": "list", "meta": {"title": "列表", "keepAlive": true}}},
	components: {ListTemplate},
	data() {
		return {
			loading: true,
			search: {},
			searchConfig: [
				{
					prop: "student_name",
					placeholder: "搜索学生姓名",
				},
				{
					prop: 'approve_status',
					tag: "select",
					placeholder: '筛选审批状态',
					options: [],
					label: "approve_status_name",
					value: "approve_status",
					config_: 'approve_status_list'
				},
				{
					prop: 'school_id',
					tag: "select",
					placeholder: '筛选报名校区',
					options: [],
					label: "school_name",
					value: "school_id",
					config_: 'school_data'
				},
				{
					prop: 'student_type',
					tag: "select",
					placeholder: '筛选学生类型',
					options: [],
					label: "student_type_name",
					value: "student_type",
					config_: 'student_type_list'
				},
				{
					prop: 'business_line',
					tag: "select",
					placeholder: '筛选所属业务线',
					options: [],
					label: "business_line_name",
					value: "business_line",
					config_: 'business_line_list'
				},
				{
					prop: 'payment_type',
					tag: "select",
					placeholder: '筛选缴费方式',
					options: [],
					label: "payment_type_name",
					value: "payment_type",
					config_: 'payment_type_list'
				},
				{
					prop: 'living_pay',
					tag: "select",
					placeholder: '筛选是否缴纳高一学年生活费',
					options: [],
					label: "living_pay_name",
					value: "living_pay",
					config_: 'living_pay_list'
				},
			],
			tableData: [],
			//总条数
			total: 0,
			pageCount: 0,
			tableConfig: [
				{
					prop: "status", label: "审批状态",
					render(row) {
						return `<div style="color: ${row['approve_status'] === '待审批' ? '#F6AD3C' : row['approve_status'] === '已通过' ? '#23C343' : '#F53F3F'}">${row['approve_status']}</div>`
					}
				},
				{prop: "student_name", label: "学生姓名"},
				{prop: "student_type", label: "学生类型"},
				{prop: "business_line", label: "所属业务线"},
				{prop: "school_name", label: "报名校区"},
				{prop: "payment_type", label: "缴费方式"},
				{prop: "living_pay", label: "是否缴纳高一学年生活费"},
				{prop: "payment_amount", label: "高一学年缴费金额"},
				{prop: "creator", label: "录入人"},
				{prop: "created_at", label: "录入时间"},
				{
					prop: "handle",
					label: "操作",
					width: "120rem",
					handle: true,
				},
			],
		}
	},
	created() {
		this.$store.commit("setPage", 1);
		this.getData()
		this.$_register('api/recruit-v2/offline-fresh/config').then(res => {
			this.searchConfig.forEach((item, n) => {
				if (!!item.config_) {
					this.searchConfig[n].options = res.data.data[item.config_]
				}
			})
		})
	},
	activated() {
		this.getData()
	},
	computed: {
		...mapState(["page"])
	},
	methods: {
		getData() {
			this.loading = true
			let params = {...this.search, page: this.page}
			this.tableData = [];
			this.$_register('api/recruit-v2/approve-fresh/list', {params}).then(res => {
				let data = res.data.data
				this.tableData = data.list
				this.total = data.page.total
			}).finally(() => {
				this.loading = false
			})
		},

		onSearch(val) {
			this.$store.commit('setPage', 1);
			this.search = val;
			this.getData(val)
		},

		handleEdit(row, text) {
			switch (text.type) {
				case 'approve':
					this.$router.push('./edit?id=' + row.id)
					break;
				case 'view':
					this.$router.push('./look?id=' + row.id)
					break;
			}
		},

		handleCurrentChange(val) {
			this.$store.commit("setPage", val);
			this.getData()
		},

		onDownload() {
			let params = {...this.search, page: this.page}
			this.$_register('api/recruit-v2/approve-fresh/export', {params}).then(res => {
				tools.download('应届生补录收费审批 ', res.data)
			})
		}

	},
}
</script>

<style lang="scss" scoped>

.role-list {
	padding-top: 24rem;
}

.button {
	border-radius: 4rem;
	box-sizing: border-box;
	height: 34rem;
	margin-bottom: 30rem;
	margin-left: 34rem;
	opacity: 1;
	width: 90rem;
}

.table {
	margin-left: 32rem;
}


::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
	box-sizing: border-box;
	color: #333333;
	font-size: 14rem;
	font-weight: normal;
	height: 48rem;
	text-align: left;
}

.header {
	color: #333333;
	font-size: 16rem;
	font-weight: bold;
	left: 300rem;
	letter-spacing: 3.5rem;
	line-height: 24rem;
	position: fixed;
	top: 120rem;
}

.el-button--text {
	color: #3491fa;
	font-size: 14rem;
	font-weight: normal;
	margin-right: 40rem;
}
</style>
